<template>
  <div class="dashboard">
    欢迎使用幸福绩效运营后台
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  width: 100%;
  height: calc(100vh - 140px);
  text-align: center;
  color: $blue;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
</style>
